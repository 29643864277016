<!--
 * @Description: What's this for
 * @Autor: zhangzhang
 * @Date: 2022-01-13 15:31:38
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-12-29 18:53:59
-->
<template>
  <div class="control">
    <control-widgets />

    <control-panel />

    <control-config />
  </div>
</template>

<script>
import ControlWidgets from './ControlWidgets.vue'
import ControlPanel from './ControlPanel.vue'
import ControlConfig from './ControlConfig.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'control',

  components: {
    ControlWidgets,
    ControlPanel,
    ControlConfig
  },

  provide() {
    return {
      control: this
    }
  },

  data() {
    return {
      dragstatus: false,
      curWidget: null,
      dragWidget: null
    }
  },

  computed: {
    ...mapGetters(['curPage'])
  }
}
</script>

<style lang="scss">
.control {
  position: relative;
  height: 100%;
  overflow: hidden;
}
</style>
