var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel"},[_c('el-alert',{attrs:{"type":"warning","show-icon":"","title":"使用：请拖动←左侧功能到此栏目下↓（拖动摆放到此处）"}}),_c('el-alert',{attrs:{"type":"warning","show-icon":"","title":"保存：当前设置更改后，需点击顶部右上角 → “保存发布”，保存后才可生效。"}}),_c('div',{staticClass:"zoom",style:({
      transform: 'scale(' + _vm.scale + ')',
      width: _vm.widthPx + 'px',
    })},[_c('phone-ctn',[_c('div',{staticClass:"page"},[(_vm.initIframe)?_c('iframe',{ref:"iframe",staticClass:"page-iframe",style:({
            height: _vm.iframeHeight + 'px',
            pointerEvents: _vm.control.dragstatus ? 'none' : 'auto',
          }),attrs:{"frameborder":"no","src":_vm.iframeUrl},on:{"load":_vm.init}}):_vm._e(),_c('div',{staticClass:"page-layer",style:({
            height: _vm.iframeHeight + 'px',
            zIndex: _vm.control.dragstatus ? '20' : '1',
          }),attrs:{"type":"page"},on:{"dragover":_vm.layerMove}},_vm._l((_vm.widgetInfoList),function(item){return _c('control-widget-shape',{key:item.id,attrs:{"data":item},on:{"changeCurrWidget":_vm.changeCurrWidget}},[_c('div',{ref:"layerWidget",refInFor:true,staticClass:"page-layer-widget",style:({ height: item.height + 'px' }),attrs:{"type":"widget","id":item.id}})])}),1)]),_c('el-tooltip',{attrs:{"effect":"light","content":"页面设置","placement":"bottom"}},[_c('div',{staticClass:"panel-set flex-center",on:{"click":function($event){_vm.control.curWidget = undefined}}},[_c('i',{staticClass:"el-icon-setting"})])])],1)],1),_c('div',{staticClass:"zoomBtn"},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-monitor","circle":""},on:{"click":function($event){_vm.widthPx = 1920}}},[_vm._v("PC ")]),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-mobile","circle":""},on:{"click":function($event){_vm.widthPx = 750}}},[_vm._v("H5 ")]),_c('el-button',{attrs:{"type":"warning","icon":"el-icon-plus","circle":""},on:{"click":function($event){_vm.scale += 0.1}}},[_vm._v("放大 ")]),_c('el-button',{attrs:{"type":"success","icon":"el-icon-minus","circle":""},on:{"click":function($event){_vm.scale -= 0.1}}},[_vm._v("缩小 ")]),_c('el-button',{attrs:{"type":"warning","icon":"el-icon-plus","circle":""},on:{"click":function($event){_vm.widthPx += 100}}},[_vm._v("增宽 ")]),_c('el-button',{attrs:{"type":"success","icon":"el-icon-minus","circle":""},on:{"click":_vm.narrow}},[_vm._v("减长 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }