import http from '@/utils/cloud';
export default async function getViewUrl() {
  //服务器阿里云参数;
  const res = await http.POST('/jw-admin', {
    module: 'settings',
    operation: 'getSystem',
  });
  const url = process.env.NODE_ENV == 'development' ? 'http://localhost:9988' : res.viewUrl;
  return url;
}
