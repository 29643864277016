<!--
 * @Description: 物料列表 
 * @Autor: JinBiao
 * @Date: 2021-05-19 10:41:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-05 15:13:18
-->

<template>
  <div class="wrap">
    <div class="selPage">
      <div class="title">页面配置</div>
      <el-select v-model="pageSel" placeholder="请选择页面" @change="editPage">
        <el-option v-for="item in project.pages" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </div>
    <div class="w-100 bg-white" style="border-bottom: 10px solid #f5f5f5; height: 100vh" v-for="(list, num) of tabList" :key="num">
      <p class="pt20 p10" style="border-bottom: 1px solid #f5f5f5">
        {{ list.title }}
      </p>
      <div class="tabList">
        <div class="menu">
          <ul>
            <li :class="tabIndex == item.name ? 'cor' : ''" v-for="(item, index) of list.tab" :key="index" @click="tabIndex = item.name">
              <span>{{ item.name || item.title }}</span>
            </li>
          </ul>
        </div>
        <ul class="flex flex-1 flex-wrap rowList">
          <li
            v-for="(item, index) in list.list"
            v-if="tabIndex == '' ? item.name.indexOf('卡片') == -1 && item.name.indexOf('特效') == -1 : !list.tab || item.name.indexOf(tabIndex) > -1"
            :key="index"
            class="item"
            :data-component="item.component"
            draggable
            @dragstart="handleDragStart"
            @dragend="dragEnd"
          >
            <el-popover placement="right-start" :close-delay="1" :title="item.name.indexOf('】') > -1 ? item.name.split('】')[1] : item.name" trigger="hover">
              <div
                class="pic"
                v-if="item.pic"
                :style="{
                  background: 'url(' + item.pic + ')no-repeat center center',
                  backgroundSize: 'contain '
                }"
              ></div>
              <div v-else>暂无略缩图</div>
              <div slot="reference" class="card">
                <i class="icon" :class="item.icon"></i>
                <span>{{ item.name.indexOf('】') > -1 ? item.name.split('】')[1] : item.name }}</span>
              </div>
            </el-popover>
          </li>
        </ul>
      </div>
    </div>
    {{ homePage }}
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  inject: ['control'],
  data() {
    return {
      pageList: [],
      pageSel: '',
      tabList: [
        {
          title: 'DIY模块组件',
          list: []
        }
      ],
      tabIndex: ''
    }
  },
  computed: {
    ...mapGetters(['project', 'curPage'])
  },
  methods: {
    ...mapMutations(['setCurPage']),
    editPage(id) {
      let page = this.project.pages.find(page => page.id == id)
      this.setCurPage(page)
    },
    // 拖拽开始
    handleDragStart(e) {
      this.control.dragWidget = this.$getNewComponent(e.target.dataset.component)
      this.control.dragstatus = true
    },

    // 拖拽结束
    dragEnd(e) {
      this.control.h5Iframe.contentWindow.postMessage(
        {
          even: 'drop',
          params: this.control.dragWidget
        },
        '*'
      )

      this.control.dragstatus = false
    }
  },
  created() {
    this.pageSel = this.curPage.id
    for (let index in this.$initializing) {
      this.tabList[0].tab = [
        {
          name: '',
          title: '基础',
          icon: ''
        },
        {
          name: '特效',
          title: '特效',
          icon: ''
        },
        {
          name: '卡片',
          title: '卡片',
          icon: ''
        }
      ]
      if (this.$initializing[index].name.indexOf('特效') > -1) {
        // 功能组件
        this.tabList[0].list.push(this.$initializing[index])
      } else if (this.$initializing[index].name.indexOf('卡片') > -1) {
        // 模块组件
        this.tabList[0].list.push(this.$initializing[index])
      } else {
        // 基础组件
        this.tabList[0].list.push(this.$initializing[index])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tabList {
  display: flex;

  .rowList {
    align-content: baseline;
    overflow-y: scroll;
    height: calc(100vh - 200px);
  }
  .menu {
    border-right: 1px solid #f5f5f5;
    ul {
      .cor {
        color: #2373ff;
        background-color: rgba(35, 115, 255, 0.08);
      }
      li {
        i {
          margin-bottom: 5px;
        }
        width: 56px;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;
        cursor: pointer;
      }
    }
  }
}
.menu {
  ul {
    li {
      i {
        font-size: 23px;
      }

      font-size: 13px;
    }
  }
}
.wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 240px;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  .selPage {
    padding: 10px 10px 0;
    .title {
      padding: 10px 0;
    }
  }
}
.pic {
  width: 400px;
  height: 200px;
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  > span {
    width: 100%;
    height: 100%;
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    font-size: 12px;
    color: #666;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      color: #fff;
      background: $color-theme;
    }
  }
  i {
    font-size: 29px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  &:hover {
    color: #fff;
    background: $color-theme;
  }
}
</style>
