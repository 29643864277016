<!--
 * @Description: 页面构建
 * @Autor: zhangzhang
 * @Date: 2021-05-19 09:49:33
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-01-11 20:01:59
-->
<template>
  <div class="build">
    <control />
  </div>
</template>

<script>
import Control from '@/components/Control'

export default {
  components: {
    Control
  }
}
</script>
<style lang="scss" scoped>
.build {
  height: calc(100vh - 56px);
  background: #f7f8fa;
}
</style>
